<template>
	<div class="dF ml-3" :class="rightToolbarOpen ? 'right-tool-open' : ''">
        <div id="fieldOptions" style="overflow: hidden;" class="h-full bg-offwhite-light dF" >
            
            <div class="dF fC right-tool-bar" >

                <div class="f1 p-4 dF fC relative" style="overflow:hidden;">


                    <div class="dF jSB mb-3">
                            <div>
                                {{currentRightToolBar[rightBarSelected] == 'IsAgent' ? 'Is Agent' : currentRightToolBar[rightBarSelected] == 'Child' ? 'Select Children':currentRightToolBar[rightBarSelected]}}
                            </div>
                            <i class="fa fa-times hover:text-primary" style="cursor:pointer" @click="$store.commit('CLOSE_RIGHT_BAR')"></i>
                    </div>

                    <div class="f1 hide-scrollbar" style="overflow-x:hidden; overflow-y:scroll;">
                        <div>
                            <template v-for="(field,fieldI) in fieldList">
                                <div v-if="rightBarSelected === field.id" :key="field+fieldI">
                                    <slot :name="field.id"></slot>
                                </div>
                            </template>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <div class="rightBarToggle cursor-pointer hide-scrollbar relative" style="overflow-y: scroll; overflow-x: hidden;">
                <template v-for="(field,fieldI) in fieldList">
                    <a-tooltip :title="field.label" placement="left" :key="field+fieldI"  overlayClassName="change-tooltip-color">
                        <div class="right-toolbar-item py-3" :class="rightBarSelected === field.id? 'active' : ''" @click="$store.commit('OPEN_RIGHT_BAR', field.id)">
                            <span v-html="icons[field.type]"></span>
                        </div>
                    </a-tooltip>
                </template>
				<div class="field-blur"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
	components:{
		
    },
    props: {
		fieldList:{
			default:[],
			type:Array
        },
        icons:{
            default:{},
            type:Object
        },
	},
	data() {
		return {
			layout: {
				labelCol: { span: 6 },
				wrapperCol: { span: 14 },
			},
			timeNow:Date.now(),
		}
	},
	watch:{
		
	},
	computed:{
        rightBarSelected(){
            return this.$store.state.rightbar
        },
		currentRightToolBar(){
			console.log('FIELDLISTTT', this.fieldList)
            let obj = {}
            this.fieldList.forEach( (field,fieldI) => {
                console.log('FIELDDDDDDDD', this.fieldList[fieldI], field.type)
				if (field && field.type){
					obj[field.id] = field.type.charAt(0).toUpperCase() + field.type.slice(1);
				}
            })
			return obj
		},
		rightToolbarOpen(){
			return Boolean(this.rightBarSelected)
		},
		instance(){
			return this.$store.state.instance
		}
	},
	methods:{ 
        
	},
	async created(){

	},
}
</script>

<style lang="scss">
	.field-blur {
		background-color:white;
		filter: blur(12px);
		overflow:hidden;
		position:fixed;
		bottom:-10px;
		right:0;
		height:40px;
		width:46px;
		z-index: 100;
	}
	body{
		
		--fieldType1:var(--primary);
	}
	.rightBarToggle{
		width:46px;
		background:white;
		display:flex; 
		flex-direction: column;
		cursor:pointer;
	
		> .right-toolbar-item{
			width:46px;
			display:flex;
			align-items: center;
			justify-content: center;
			&.active{
				background:#f8f8f8;
			}
		}
		
	}
	.page-popover{
		z-index:1000000;	
		.ant-popover-inner-content{
			padding:0;
		}
		.more-item{
			padding:4px 8px;
			cursor: pointer;
			&:hover{
				background:var(--off-white-light);
			}
		}
	}
	.right-tool-bar{
		transition:width .3s ease-out;
	}
	

	.pdf-toolbar{
		>div {
			padding:10px;
			color:var(--new-med-gray);
			transition: background .3s ease-out;
			&.active{
				color:var(--primary);
			}
			&:not(:last-child):hover{
				// background:var(--light-purple);
				background:rgba(100, 100, 100, 0.05);
			}
			&.tool-bL{
				border-left:1px solid var(--light-purple);
			}
			> svg{
				width:20px;
				height:20px;
			}
		}
	}

	#wcp-editor-toolbar-wrap{
		display:none;
	}
	#wcp-editor{
		background:transparent;
	}
	.page-modal-container{
		
		position: fixed;
		left:0;
		width:100vw;
		top:0;
		height:100vh;
		z-index:10000;
		pointer-events: none;
		.page-modal-content-wrapper,.page-modal-container-mask{
			position: absolute;
			left:0;
			top:0;
			width:100vw;
			height:100vh;
		}
		.page-modal-container-mask{
			// background:rgba(0,0,0,0.8);
			background:rgba(68, 66, 64, 0.84);
			pointer-events: auto;
		}

		.page-modal-content-wrapper{
			
			>.container{
				position: absolute;
				left:50%;
				transform:translateX(-50%);
				right:0;
				top:0;
				bottom:0;
				pointer-events: auto;
				overflow: hidden;
				
			}
			.ant-card-actions{
				background:var(--light-gray);
			}
			
		}

	}
	.fade-enter-active, .fade-leave-active {
		transition: all .5s;
		*{transition: all .5s;}
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
		*{opacity:0}
	}
	.field-options-footer{
		// min-height:500px;
		// background:#f7f5f9;
	}
	.muted-colors{
		color:#bfbfbf;
	}
	.right-tool-bar{
		width:0px;
		overflow:hidden;
	}
	.right-tool-open{
		.right-tool-bar{
			width: 300px;
    		overflow: hidden;
		}
	}
	.pdf-canvas-wrapper{
		flex:1;
		position: relative;
	}
	#fieldOptions{
		transition:width .3s ease-out;
		border-left:1px solid var(--light-purple);
		// width:25%;
	}
	.page-block{
		position: relative;
		margin-bottom:40px;
		&:after{
			display:block;
			text-align:center;
			content:attr(data-page-index);
			margin-top:10px;
		}
		
		> ul{
			list-style: none;
			margin:10px 0 0;
			padding:0;
			position: absolute;
			right:0;
			top:0;
			display:flex;
			flex-direction: column;
			> li{
				margin-bottom:10px;
			}
		}
		flex:0 0 auto;
		> .page-img-holder{
			border:1px solid var(--light-purple);
		}
		img{
			width:100%;
			max-width:100%;
			height:100%;
			max-height:100%;
			object-fit: contain;
		}

		&.page-selected{
			&:after{
				color:var(--primary);
			}	
			> .page-img-holder{
				border-color:var(--primary);
			}
		}
		&:hover{
			// 1px 3px 10px rgba(100,100,100,0.1)
		}


	}
	.page-block-container{
		display:flex;
		flex-wrap: wrap;
		padding:100px 50px;
	}
	.draggable-canvas{
			// display:none;
		position:absolute;
		left:0;
		// right:0;
		top:0;
		width:1px; 
		height:1px;
		// bottom:0;
		// background:rgba(100,100,100,0.3);
		pointer-events: none;
		opacity:0;
	}
	[data-field-value]{
		padding:1px 2px;
	}
	[data-field-value=contact1]{
		background:rgba(157, 198, 202, 0.55);
		color:black !important;
	}
	[data-field-value=contact2]{
		background:rgba(239, 25, 25, 0.55);
		color:black !important;
	}

	#wcp-editor-center{
		margin-left:0;
	}
	.imp-editor-shape.selected .imp-selection{
		background:rgba(200,200,200,0.1);
		border:1px solid var(--selectColor, turquoise);
		border-radius:0px !important;
	}
	#imp-editor-shapes-container::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		border: 3px dashed transparent;
		transition: border .3s ease-in-out;
		pointer-events: none;
	}
	body.field-dragging{
		#imp-editor-shapes-container::after{
			border-color:#aaa;
		}
	}

	.each-field{
		::selection{
			background:none;
		}
		&:hover{
			background:#f3f3f3;
		}
	}
	.hide-scrollbar::-webkit-scrollbar{
		display:none;
	}
	.scroll-parent{
		position: relative;
		overflow:hidden;
		> .scroll-child{
			position: absolute;
			left:0;
			bottom:0;
			top:0;
			right: -17px;
			overflow-y: scroll;
		}
	}

	.imp-editor-shape.selected {
		svg{
			box-shadow:0 0 5px;
		}
		.select-rect{
			stroke:inherit;
		}
		text{
			fill:inherit;
		}
	}

	.custom-field-item{
		.handle{
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		&:not(:hover){
			.custom-field__edit{
				opacity:0;
			}
		}
		
	}

	
	span.purchaserNo{
		color:#aaa;
	}

	.pdf-toolbar *, .no-select, .no-select-parent > *{
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none; 
	}
	
</style>